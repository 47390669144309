import { render, staticRenderFns } from "./NotificationSettings.vue?vue&type=template&id=2e55634f&scoped=true"
import script from "./NotificationSettings.vue?vue&type=script&lang=js"
export * from "./NotificationSettings.vue?vue&type=script&lang=js"
import style0 from "./NotificationSettings.vue?vue&type=style&index=0&id=2e55634f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e55634f",
  null
  
)

export default component.exports