import { render, staticRenderFns } from "./EmptyStates.vue?vue&type=template&id=07721f5c&scoped=true"
import script from "./EmptyStates.vue?vue&type=script&lang=js"
export * from "./EmptyStates.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07721f5c",
  null
  
)

export default component.exports