import { render, staticRenderFns } from "./DisputeDialog.vue?vue&type=template&id=098a73fe&scoped=true"
import script from "./DisputeDialog.vue?vue&type=script&lang=js"
export * from "./DisputeDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "098a73fe",
  null
  
)

export default component.exports